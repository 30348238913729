
export default {
    multicallAddress: {
        1: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        56: '0x1BD3a4FCD0C24eb843f01AE39b039b5d321E9c62',
        // 97: '0x5a97FD5Ea9b025f38c08EF5198e6779830c5188E',
        97: '0x672F5Df3F25FB7b63f665aE97a9B28d12Aba6B8F',
        137: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        8453: '0x8b01b43d1C02Df8529987268C6412D41406b542b',
    },
    factoryAddress: {
        1: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        56: '0x528aCb70DC0c0355286570164fd36C7E8C413698',
        // 97: '0xeC7e8c6D31A6813a37b30923B602Da2793fd858B',
        97: '0xEF48407D9f0E9a61f42f0B87ab5b014f01E96354',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0xCb0429bD8EbE2da227070766F98be42d4cc85B28',
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
    },
    routerAddress: {
        1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
        137: '0xedf6066a2b290C185783862C7F4776A2C8077AD1',
        8453: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        97: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
    },
}